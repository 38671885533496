import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Seo from "../../components/layout/seo";
import Metal from "../../components/pages/metallo-duro";

const data = {
  intro: (
    <p>
      The carbide used to manufactured a blade’s tips is the most important part
      of the tool.
      <br />
      <br />
      The International Saws, aware of this, has been working in the research of
      the most suitable HW and available on the market to ensure best
      performance tools on wood derivatives, aluminum, plastic and iron.
    </p>
  ),
  blocks: [
    {
      type: "comparison",
      title: "Micro-grain carbide",
      text: (
        <p>
          The use of extremely fine-grained powders (micrograin) has made the
          carbide more compact and has therefore reduced the possibility of
          abrasion of the cutting edge, which has improved the cutting quality
          as well as the life of the blade.
          <br />
          <br />
          The carbide we use gives the cutting edge the abrasion resistance
          abrasion resistance, which is essential for good, long-lasting cutting
          quality. cutting quality.
          <br />
          <br />
          The competitors blade being all the more exposed to abrasion will have
          an increasing wearing effect on the cutters therefore leading to a
          reduction of the duration of the blade itself.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../../resources/images/specifiche/micgrano-is.png"
          layout="fullWidth"
          alt="the micro-grains of international saws"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../../resources/images/specifiche/micgrano-comp.png"
          layout="fullWidth"
          alt="the grains of competitors' blades"
        />
      ),
    },
    {
      type: "comparison",
      title: "Maggior durata e resistenzaIncreased durability and resistance",
      text: (
        <p>
          International Saws manufactures its own carbide. By adding titanium to
          our carbide, we have improved the life of the tips and reduced
          consequences from chemical attack from resins and glues contained in
          wood and wood products.
          <br />
          <br />
          International Saws’s HW alloy metal contains properties of extreme
          hardness, wearresistance and high temperature stability. It is
          obtained by mixing Tungsten carbide, Titanium and others metals under
          powdered form. The mixture is then pressed and sintherized at extreme
          temperature and pressure.
          <br />
          <br />
          Other producers of tools acquire their HW from various suppliers,
          therefore they are not capable of maintaining consant quality, nor
          changing the HW again with respect to the various wood to be worked.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../../resources/images/specifiche/res-is.png"
          layout="fullWidth"
          alt="international saws metal"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../../resources/images/specifiche/res-comp.png"
          layout="fullWidth"
          alt="the metal of competitors' blades"
        />
      ),
    },
  ],
};

const HardMetal = ({ location }) => (
  <>
    <Seo title="Hard metals tools" desc="" />
    <Metal data={data} location={location} />
  </>
);

export default HardMetal;
